<template>
    <v-card class="d-flex flex-column" width="300">
        <v-card-text>
            <v-row>
                <v-col cols="2">
                    <v-switch
                        dense
                        v-model="fromEnabled"
                    ></v-switch>
                </v-col>
                <v-col>
                    <DateSelector
                        :formDates.sync="from"
                        :label="'Inizio Validità'"
                        :disabled="!fromEnabled"
                    ></DateSelector>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">
                    <v-switch
                        dense
                        v-model="toEnabled"
                    ></v-switch>
                </v-col>
                <v-col>
                    <DateSelector
                        :formDates.sync="to"
                        :label="'Fine Validità'"
                        :disabled="!toEnabled"
                    ></DateSelector>
                </v-col>
            </v-row>
                <v-switch
                    dense
                    v-model="expired"
                    label="scaduti"
                ></v-switch>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export const DateSelector = () => import("../core/DateSelector");

import * as filterActions from "../../store/actions/filter";
export default {

    components: {
        DateSelector
    },

    computed: {
        ...mapState({
            promeFileFilter: state => state.filterModule.promeFileFilter,
        }),

        fromEnabled: {
            get() {
                return this.promeFileFilter.fromEnabled;
            },
            set(newValue) {
                this.updatePromeFileFilterSwitch({key: 'fromEnabled',value: newValue});
            }
        },

        toEnabled: {
            get() {
                return this.promeFileFilter.toEnabled;
            },
            set(newValue) {
                this.updatePromeFileFilterSwitch({key: 'toEnabled',value: newValue});
            }
        },

        from: {
            get() {
                return this.promeFileFilter.from.substring(0, 10);
            },
            set(newValue) {
                this.updatePromeFileFilterSwitch({key: 'from',value: `${newValue}T00:00:00Z`});
            }
        },

        to: {
            get() {
                return this.promeFileFilter.to.substring(0, 10);
            },
            set(newValue) {
                this.updatePromeFileFilterSwitch({key: 'to',value: `${newValue}T00:00:00Z`});
            }
        },

        expired: {
            get() {
                return this.promeFileFilter.expired;
            },
            set(newValue) {
                this.updatePromeFileFilterSwitch({key: 'expired',value: newValue});
            }
        },
    },

    methods: {
        ...mapMutations({
            updatePromeFileFilterSwitch:filterActions.FILTER_PROMEFILES_SET_SWITCH
        })
    }
};
</script>

<style scoped>

.option-menu.v-btn::before {
  opacity: 0;
}
</style>
